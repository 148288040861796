var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"x67C-TKHNIpmi595B5H6c"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the server.
// The config you add here will be used whenever the server handles a request.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init as InitSentry } from '@sentry/nextjs';
import getConfig from 'next/config';

const config = getConfig();


// todo(ADVOYRIS): removed hard coded value
InitSentry({
  dsn: 'https://2d904fa3c626488da113a69da3a64951@apps-errors.overwolf.com/101',
  tracesSampleRate: 0.1,
  sampleRate: 0.1,
  attachStacktrace: true,
  autoSessionTracking: false,
});
